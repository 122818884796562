import Danger from "./Danger";

export default function Alert({ variant="primary", tag="Primary", message="" }) {
  const AlertBox = variant === "danger" ? Danger : Danger;
  return (
    <AlertBox 
      message={message} 
      {...(tag !== "Primary") && {
        tag
      }}
    />
  )
}
