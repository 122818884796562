import React, { useMemo } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Logo from "components/logo";
import { RouterLink } from "routes/components";
import { paths } from "routes/paths";
import useData from "hooks/use-data";
import { isEmpty } from "utils";
import Markdown from "components/markdown";
import Iconify from "components/Iconify";
import { IconButton } from "components/misc";
import { SectionEdit } from "components/edit-section";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/3`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw(RouterLink)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default function FiveColumnDark() {
  const { pageData } = useData();
  const data = useMemo(() => pageData?.FooterData?.value[0],[pageData?.FooterData]);

  const socialLinks = useMemo(
    () =>
      [
        data?.fbLink && {
          value: 'facebook',
          label: 'FaceBook',
          icon: 'carbon:logo-facebook',
          color: '#1877F2',
          link: data?.fbLink || '',
        },
        data?.instaLink && {
          value: 'instagram',
          label: 'Instagram',
          icon: 'carbon:logo-instagram',
          color: '#E02D69',
          link: data?.instaLink || '',
        },
        data?.linkedinLink && {
          value: 'linkedin',
          label: 'Linkedin',
          icon: 'carbon:logo-linkedin',
          color: '#007EBB',
          link: data?.linkedinLink || '',
        },
        data?.twitterLink && {
          value: 'twitter',
          label: 'Twitter',
          icon: 'carbon:logo-twitter',
          color: '#00AAEC',
          link: data?.twitterLink || '',
        },
        data?.youtubeLink && {
          value: 'youtube',
          label: 'Youtube',
          icon: 'carbon:logo-youtube',
          color: '#ff0000',
          link: data?.youtubeLink || '',
        },
      ]?.filter((d) => !isEmpty(d)),
    [data]
  );


  return (
    <Container>
      <SectionEdit data={pageData?.FooterData} hideAddButton hideRemoveButton colView>
        <Content>
          <FiveColumns>
            <CompanyColumn>
              <LogoContainer>
                <Logo mode="dark" />
              </LogoContainer>
              <CompanyAddress>
                <Markdown content={data?.note} />
              </CompanyAddress>
                
              {socialLinks?.length > 0 && (
                <SocialLinksContainer>
                  {socialLinks.map((social) => (
                    <SocialLink tw="p-0" key={social.value} href={social.link} target="_blank">
                      <IconButton tw="bg-white">
                        <Iconify icon={social.icon} sx={{ color: social.color }} />
                      </IconButton>
                    </SocialLink>
                  ))}
              </SocialLinksContainer>
              )}
            </CompanyColumn>
            <Column>
              <ColumnHeading>Services</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <RouterLink href={paths.services}>Web Development</RouterLink> 
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.services}>Mobile Development</RouterLink>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.services}>Desktop Development</RouterLink>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.services}>Migration & Re-engineering</RouterLink>
                </LinkListItem>
              </LinkList>
            </Column>
            <Column>
              <ColumnHeading>Quick Links</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <RouterLink href={paths.blog}>Blog</RouterLink>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.faqs}>FAQs</RouterLink>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.industries}>Industries</RouterLink>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.caseStudies}>Case Studies</RouterLink>
                </LinkListItem>
              </LinkList>
            </Column>
            <Column>
              <ColumnHeading>Contact</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Markdown content={data?.phone} />                  
                </LinkListItem>
                <LinkListItem>
                  <Link href={`mailto:${data?.email}`}>
                    <Markdown content={data?.email} />                  
                  </Link>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.contact}>Contact</RouterLink>
                </LinkListItem>
                <LinkListItem>
                  <RouterLink href={paths.about}>About Us</RouterLink>
                </LinkListItem>
              </LinkList>
            </Column>
          </FiveColumns>
          <Divider/>
          <CopyrightAndCompanyInfoRow>
            <CopyrightNotice>
              <Markdown content={data?.copyright} />
            </CopyrightNotice>
            <CompanyInfo>
              <RouterLink href={paths.disclaimer}>Disclaimer</RouterLink>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <RouterLink href={paths.privacyPolicy}>Privacy Policy</RouterLink>
            </CompanyInfo>
          </CopyrightAndCompanyInfoRow>
        </Content>
      </SectionEdit>
    </Container>
  )
};
