/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import fileToBase64 from './fileToBase64';

const filesConvertInBase64 = async (files) => {
  // console.log(files);
  const documents = [];
  if (window.File && window.FileList && window.FileReader) {
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        const doc = await fileToBase64(files[i]);
        documents.push(doc);
      }
    }
  } else {
    alert("Your browser doesn't support to File API");
  }
  return documents;
};
export default filesConvertInBase64;
