/* eslint-disable */
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import SimpleLayout from 'layouts/simple';

// ----------------------------------------------------------------------

const ComingSoonPage = lazy(() => import('pages/coming-soon'));
const MaintenancePage = lazy(() => import('pages/maintenance'));

// ----------------------------------------------------------------------

export const commonRoutes = [
  {
    element: (
      <SimpleLayout>
        <Outlet />
      </SimpleLayout>
    ),
    children: [
      // { path: 'coming-soon', element: <ComingSoonPage /> },
      // { path: 'maintenance', element: <MaintenancePage /> },
    ],
  },
];
