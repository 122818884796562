/* eslint-disable no-return-await */
/* eslint-disable import/no-cycle */
import { APIService, API_CONSUMER } from '..';

const BaseURL = `${API_CONSUMER}/api/v1/user`;

export default {
  getUserData: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getUserData`, method: 'POST' }),

  // updateUserProfilePicture: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateUserProfilePicture`, method: 'POST' }),

  // updateUserProfile: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateUserProfile`, method: 'POST' }),

};
