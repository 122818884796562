import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'layouts/main';

import SplashScreen from 'components/splash-screen';

import { commonRoutes } from './common';
import { authRoutes } from './auth';
import { errorRoutes } from './error';


// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('pages/auth/login'));

const Home = lazy(() => import('pages/home'));
const ContactUs = lazy(() => import('pages/contact-us'));
const AboutUs = lazy(() => import('pages/about-us'));

const Services = lazy(() => import('pages/services'));
const SingleService = lazy(() => import('pages/services/single-service'));


const Industries = lazy(() => import('pages/industries'));
const SingleIndustries = lazy(() => import('pages/industries/single-industries'));

const CaseStudies = lazy(() => import('pages/case-studies'));
const SingleProjectStudy = lazy(() => import('pages/case-studies/single-project-study'));

const PrivacyPolicyPage = lazy(() => import('pages/privacy-policy'));
const DisclaimerPage = lazy(() => import('pages/disclaimer'));
const FAQsPage = lazy(() => import('pages/faqs'));


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: (
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          element: (
            <MainLayout>
              <Outlet />
            </MainLayout>
          ),
          children: [
            { element: <Home />, index: true, },
            { path: 'contact', element: <ContactUs /> },
            { path: 'about-us', element: <AboutUs /> },
            { 
              path: 'services', 
              children: [
                { element: <Services />, index: true, },
                { path: '*', element: <SingleService /> },
              ]
            },
            { 
              path: 'industries', 
              children: [
                { element: <Industries />, index: true, },
                { path: '*', element: <SingleIndustries /> },
              ]
            },
            { 
              path: 'case-studies', 
              children: [
                { element: <CaseStudies />, index: true, },
                { path: '*', element: <SingleProjectStudy /> },
              ]
            },            
            { path: 'privacypolicy', element: <PrivacyPolicyPage /> },
            { path: 'disclaimer', element: <DisclaimerPage /> },
            { path: 'faqs', element: <FAQsPage /> },
          ],
        },
        
        // { path: 'blogs', element: <LoginPage /> },
        { path: 'admin', element: <LoginPage /> },

        ...authRoutes,

        ...errorRoutes,

        ...commonRoutes,

        { path: '*', element: <Navigate to="/error/404" replace /> },
      ],
    },
  ]);
}