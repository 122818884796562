import { paths } from 'routes/paths';

// ----------------------------------------------------------------------

export const pageLinks = [
  {
    order: '1',
    subheader: 'Services',
    items: [
      { title: 'Services', path: paths.ndisHub },
      { title: 'Training', path: paths.training },
      { title: 'Support Us', path: paths.services },
      { title: 'NDIS Hub', path: paths.ndisHub },
    ],
  },
  {
    order: '2',
    subheader: 'Career',
    items: [
      { title: 'Job List', path: paths.career },
      { title: 'Application Form', path: paths.careerJoin },
    ],
  },
  {
    isNew: true,
    order: '3',
    subheader: 'Contact',
    items: [
      { title: 'About Us', path: paths.about },
      { title: 'Contact', path: paths.contact },
    ],
  },
];

export const navConfig = [
  { title: 'Services', path: paths.services },
  { title: 'Case Studies', path: paths.caseStudies },
  { title: 'About Us', path: paths.about },
  { title: 'Blog', path: paths.blog, tw: "lg:ml-12!", },
  { title: "Let's Talk", path: paths.contact, isBtn: true, roundBtn: false },
  // {
  //   title: 'Page',
  //   path: '#',
  //   caption: 'This is the caption',
  //   children: [
  //     {
  //       title: 'Page 1',
  //       path: '#',
  //       caption: 'This is the caption',
  //       children: [
  //         { title: 'Page 1.1', path: '#' },
  //         { title: 'Page 1.2', path: '#' },
  //       ],
  //     },
  //     {
  //       title: 'Page 2',
  //       path: '#',
  //       children: [
  //         { title: 'Page 2.1', path: '#' },
  //         { title: 'Page 2.2', path: '#' },
  //         {
  //           title: 'Page 2.3',
  //           path: '#',
  //           children: [
  //             { title: 'Page 2.3.1', path: '#' },
  //             { title: 'Page 2.3.2', path: '#' },
  //             { title: 'Page 2.3.3', path: '#' },
  //           ],
  //         },
  //       ],
  //     },
  //     { title: 'Page 3', path: '#' },
  //   ],
  // },
];
