
import MainHeader from './main';
import LogoOnlyHeader from './logo-only';

const Headers = {
  "main": MainHeader,
  "logoOnly" : LogoOnlyHeader
};

export { 
  MainHeader,
  LogoOnlyHeader
};
export default Headers;