/* eslint-disable react-hooks/exhaustive-deps */
import { WebsiteV1API } from 'api';
import { usePathname } from 'routes/hooks/use-pathname';
/* eslint-disable react/jsx-no-constructed-context-values */
import { useEffect, useReducer, createContext } from 'react';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  message: "",

  pageData: null,
};

const handlers = {
  START_LOADING: (state) => ({
      ...state,
      message: "",
      error: null,
      isLoading: true,
    }),
  STOP_LOADING: (state) => ({
      ...state,
      message: "",
      error: null,
      isLoading: false,
    }),
  HAS_ERROR: (state, action) => ({
      ...state,
      isLoading: false,
      message: "",
      error: action.payload.error,
  }),
  
  RESET_DATA: (state, action) => ({ ...initialState }),

  UPDATE_DATA: (state, action) => {
    const { data, message } = action.payload;
    return {
      ...state,
      pageData: data || {},
      message
    };
  },
};

const DataContext = createContext({
  ...initialState,
  getWebsiteContent: () => Promise.resolve(),
});

function DataProvider({ children }) {
  const [state, dispatch] = useReducer((state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state), initialState);
  const pathname = usePathname();

  const startLoading = () => dispatch({ type: 'START_LOADING' });
  const stopLoading = () => dispatch({ type: 'STOP_LOADING' });
  const hasError = (error) => dispatch({ type: 'HAS_ERROR', payload: { error } });
  const updateData = (payload) => dispatch({ type: 'UPDATE_DATA', payload });
  const resetData = (payload) => dispatch({ type: 'RESET_DATA', payload });

  const getWebsiteContent = async ({ page }) => {
    try {
      startLoading();
      const data = await WebsiteV1API?.getWebsiteContent?.({ page });
      updateData(data);
      stopLoading();
      return data;
    } catch(error) {
      console.error(error);
      hasError(error);
      return error;
    }
  };

  useEffect(() => {
    // console.log('pathname', pathname);
    resetData();
    getWebsiteContent({ page: pathname });
  }, [pathname]);

  const values = {
    ...state,
    getWebsiteContent
  };

  return (
    <DataContext.Provider value={values}>
      {children}
    </DataContext.Provider>
  );
};

const DataConsumer = DataContext.Consumer;
export { DataContext, DataConsumer, DataProvider }
