import PropTypes from 'prop-types';
import { useFormik } from 'formik';

const Formik = ({ INITIAL_VALUES = {}, validationSchema = {}, callback, enableReinitialize = true }) =>
  useFormik({
    enableReinitialize,
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: (values, formikHelpers) => callback({ values, formikHelpers })
  });

Formik.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  enableReinitialize: PropTypes.boolean
};
export default Formik;
