import tw from "twin.macro";
import Slider from "react-slick";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

// import { IconifyIcons } from "components/Iconify";
import ReactModalAdapter from "helpers/ReactModalAdapter";

import { Info2 } from "./Typography";

export const MainContainer = tw.div`font-display min-h-screen text-secondary-500 overflow-hidden`;
export const HeaderContainer = tw.div`px-8 lg:py-4 md:py-2 sm:py-1 shadow-md fixed w-full bg-white z-50`;
export const BodyContainer = tw.div`p-8 pt-20`;

export const Container = tw.div`relative`;
export const Container2 = tw(Container)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
export const Container3 = tw.div`min-h-screen bg-primary-900 text-white font-medium flex justify-center overflow-x-hidden`;
export const Container4 = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12 justify-center`;
export const Container5 = tw.div`w-full flex-1 mt-8`;
export const Container6 = styled.div(props => [
  tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`,
  `background-image: url("${props.imageSrc}");`
]);
export const Container7 = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
export const Container8 = tw(Container)`bg-gray-900 -mx-8`

export const DecoratorBlobContainer1 = tw.div`absolute inset-0 overflow-hidden rounded-lg`;


export const HeadingContainer1 = tw.div``;

export const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const Content2 = tw.div`max-w-screen-sm m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex items-center justify-center flex-1 py-12`;
export const Content3 = tw.div`flex flex-col items-center`;
export const Content4 = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;


export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`;
export const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-500 text-gray-100`;

export const TextContent1 = tw.div`lg:py-8 text-center md:text-left`;

export const Column1 = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
export const Column2 = tw.div`flex flex-col items-center`;
export const Column3 = styled.div`${tw`md:w-1/2 lg:w-1/2 max-w-xl`}`;
export const Column4 = styled.div`${tw`md:w-1/2 lg:w-1/3 max-w-sm`}`;
export const Column5 = styled.div`${tw`lg:w-1/3 max-w-xs`}`;
export const Column6 = tw.div`max-w-2xl`


export const LeftColumn1 = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
export const RightColumn1 = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

export const TwoColumn1 = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
export const TwoColumn2 = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
export const TwoColumn3 = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
export const TwoColumn4 = styled.div`${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}`;
export const TwoColumn5 = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-4 pb-16 md:pb-20`;

export const ThreeColumn1 = styled.div`${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}`;
export const ThreeColumn2 = styled.div`${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}`;


export const Cards1 = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
export const Cards2 = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
export const Cards3 = tw.div`mt-12 flex flex-wrap -mr-3 relative`;

export const TextContainer1 = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
export const TextContainer2 = tw.div`mt-3 md:mt-0 md:ml-6`;
export const TextContainer3 = tw(Column6)``;


export const LinksContainer1 = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`


export const Card1 = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
export const Card2 = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg sm:flex-row! h-full sm:pr-4`;
export const Card3 = tw.a`flex flex-col h-full bg-gray-200 rounded shadow-md hover:shadow-lg`;
export const Card4 = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;
export const Card5 = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;
export const Card6 = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`


export const CardContent1 = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

export const CardLinks1 = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`;


export const ImageColumn1 = tw(Column1)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
export const ImageColumn2 = tw(Column1)`md:w-6/12 flex-shrink-0 relative`;



export const OpacityOverlay1 = tw.div`z-10 absolute inset-0 bg-black opacity-50`;


export const TextColumn1 = styled(Column1)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]); 
export const TextColumn2 = styled(Column1)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);




export const Image1 = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
export const PlainImage2 = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);


export const SliderContainer1 = tw.div`mt-24`;
export const Slider1 = styled(Slider)``;
export const ImageContainer1 = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {${tw`rounded`}}
`;
export const ImageContainer2 = tw(Column6)`mt-16 lg:mt-0 lg:ml-16 flex justify-end`;

export const QuoteContainer1 = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
export const Quote1 = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;

export const SliderControlButtonContainer1 = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;
export const HeaderContent1 = tw.div`w-full`;
export const FAQSContainer1 = tw.dl`mt-12 max-w-6xl relative`;
export const FAQ1 = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
export const Question1 = tw.dt`flex justify-between items-center`;
export const QuestionText1 = tw.span`text-lg lg:text-xl font-semibold`;
export const QuestionToggleIcon1 = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
export const Answer1 = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);


export const Actions1 = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
export const IllustrationContainer1 = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;
export const StyledModal1 = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;


export const LogoLink1 = tw.a``;
export const LogoImage1 = tw.img`h-12 mb-4`;
export const ToolLogoContainer1 = tw.div`flex grayscale-[100%]`;
export const ToolLogo1 = tw.img`h-8 mr-2 `;


export const Image2 = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg sm:h-80 sm:min-h-full sm:w-1/2 lg:w-1/2 sm:rounded-t-none sm:rounded-l-lg`}
`;

export const CardContainer2 = styled.div`
  ${tw`mt-10 w-full! sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props => (props.index % 2 ===0) &&
    css`
      ${Card2} {
        ${tw`sm:flex-row-reverse!`}
      }
      ${Image2} {
        ${tw`sm:rounded-l-none sm:rounded-r-lg`}
      }
      ${Info2} {
        ${tw`sm:border-l-2 sm:border-r-0`}
      }
    `
  }
`;

export const CardImage3 = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;
export const CardImage2 = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-cover bg-center rounded`}
`

export const CardText3 = tw.div`flex-1 px-12 py-12` 
export const CardTitle3 = tw.h6`text-2xl font-bold transition duration-300 `;
export const CardDescription3 = tw.p`mt-4 text-lg  leading-relaxed`;
export const CardContainer3 = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}
`;

export const UL1 = tw.ul`mt-12`;
export const LI1 = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;




export const StatsContainer1 = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-xl justify-between mx-auto`
export const Stat1 = tw.div`flex flex-col text-center p-4 tracking-wide`
export const StatLabel1 = tw.div`text-xl font-medium uppercase`
export const StatValue1 = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`


export const Form1 = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
export const Input1 = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-400 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-500 focus:bg-white mt-5 first:mt-0`;
export const Textarea1 = styled(Input1).attrs({as: "textarea"})`${tw`h-24`}`

export const Form2 = tw.form`mx-auto max-w-xs`;
export const Input2 = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;



/// Edit Section
export const SectionContainer = tw.div`rounded-sm border border-gray-400 p-5 relative my-2`;
export const TableContainer = tw.div`relative overflow-auto h-[calc(100%_-_7.5rem)]`;
export const Table = tw.table`w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400`;
export const TableHead = tw.thead`text-xs text-gray-700 uppercase bg-gray-300`;
export const TableBody = tw.tbody``;

export const TableHeadRow = tw.tr``;
export const TableHeadData = tw.th`px-6 py-3 min-w-[8rem]`;

export const TableRow = tw.tr`bg-white border-b hover:bg-gray-200`;
export const TableData = tw.td`px-6 py-4 font-medium text-gray-800`;
export const TextField = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-400 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-500 focus:bg-white mt-5 first:mt-0`;

// Text
export const MarkdownText = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;


export const Row1 = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center`;
