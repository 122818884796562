import { capitalize } from 'lodash';

// ===============================================================================
// createAvatar
// ===============================================================================
const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

function getFirstCharacter(name) {
  return capitalize(name && name.charAt(0));
}
function getAvatarColor(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'primary';
  if (INFO_NAME.includes(getFirstCharacter(name))) return 'info';
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'success';
  if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning';
  if (ERROR_NAME.includes(getFirstCharacter(name))) return 'warning';
  return 'default';
}
export function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}
// ===============================================================================

// ===============================================================================
// maskText
// ===============================================================================
export const maskText = (value) =>
  String(value || '')
    .split('')
    .map((v) => '*')
    .join('');
// ===============================================================================

// ===============================================================================
// getInitials
// ===============================================================================
export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

// ===============================================================================

// ===============================================================================
// getColumnNameFromIndex
// ===============================================================================
export function getColumnNameFromIndex(colm) {
  let column = colm - 1;

  const asciiOfA = 'A'.charCodeAt(0);
  let col = String(String.fromCharCode(asciiOfA + (column % 26)));

  while (column >= 26) {
    column = column / 26 - 1;
    col = String(String.fromCharCode(asciiOfA + (column % 26))) + col;
  }
  return col;
}
// ===============================================================================

// ===============================================================================
// FullScreen
// ===============================================================================
export function enterFullScreen() {
  const element = document.getElementById('root');
  // console.log(document.fullscreenEnabled)
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen(); // Firefox
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen(); // Safari
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen(); // IE/Edge
  }
}
export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}
// export const toggleFullScreen = () => {
//   if (
//     window.fullScreen ||
//     (window.innerWidth == screen.width && window.innerHeight == screen.height)
//   ) {
//     exitFullscreen();
//   } else {
//     enterFullScreen();
//   }
// };
// ===============================================================================

// ===============================================================================
// fakeRequest
// ===============================================================================
export const wait = (time) => new Promise((res) => setTimeout(res, time));
// ===============================================================================

// ===============================================================================
// isEmpty
// ===============================================================================
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
// ===============================================================================

// ===============================================================================
// eventBus
// ===============================================================================
export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};
// ===============================================================================

// ===============================================================================
// array/object functions
// ===============================================================================
export const objFromArray = (arr, key = 'id') =>
  arr.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});

// export function arraySorter(array, key, order = 'asc') {
//   return (array || []).sort(function innerSort(a, b) {
//     if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
//       // property doesn't exist on either object
//       return 0;
//     }

//     const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
//     const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

//     let comparison = 0;
//     if (varA > varB) {
//       comparison = 1;
//     } else if (varA < varB) {
//       comparison = -1;
//     }
//     return order === 'desc' ? comparison * -1 : comparison;
//   });
// }

// export const collapseFromArray = ({ array, key = 'id', mainFields = [] }) => {
//   const keys = [...new Set((array || []).map((dist) => dist[key]))];
//   const collapseArr = [];

//   (keys || []).map((value) => {
//     const matched = (array || []).filter((ele) => ele[key] === value);
//     const mainFieldsValues = {};
//     (mainFields || []).map((field) => {
//       if (field !== key) {
//         mainFieldsValues[field] = [...new Set((matched || []).map((dist) => dist[field]))].join(
//           '*** '
//         );
//       }
//     });
//     if (matched) collapseArr.push({ [key]: value, items: matched, ...mainFieldsValues });
//   });
//   return collapseArr;
// };
// ===============================================================================

// ===============================================================================
// elements
// ===============================================================================
// export const anchorTag = ({ href, filename }) => {
//   var element = document.createElement('a');
//   element.setAttribute('href', href);
//   element.setAttribute('download', filename);

//   element.style.display = 'none';
//   document.body.appendChild(element);

//   element.click();

//   document.body.removeChild(element);
// };
// ===============================================================================

// ===============================================================================
// convert
// ===============================================================================
export const parseJwt = (token) => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${(c.charCodeAt(0).toString(16))}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  return null;
};
export const parseJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    // console.log('json settings', e);
    return null;
  }
  return JSON.parse(str);
};
// ===============================================================================

// ===============================================================================
// convert
// ===============================================================================
export function setWithExpiry(key, value, ttl) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
export function base64encode(str) {
  if (typeof str !== 'string') {
    if (typeof str === 'number') {
      str = str.toString();
    } else {
      throw new Error('Text to encode must be a string or a number.');
    }
  }
  return Buffer.from(str, 'utf8').toString('base64');
}

export function base64decode(str) {
  if (typeof str !== 'string') {
    throw new Error('Input value must be a string.');
  }

  return Buffer.from(str, 'base64').toString('utf8');
}
// ===============================================================================
