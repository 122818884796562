import "slick-carousel/slick/slick.css";
import 'react-quill/dist/quill.snow.css';
import './utils/highlight';

import { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Modal from "react-modal";

import App from "./App";

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);