export const localStorage = {
  set: ({ key, value }) =>
    window.localStorage.setItem(key, JSON.stringify(value)),

  setAll: (items) => {
    (items || []).map(({ key, value }) =>
      window.localStorage.setItem(key, JSON.stringify(value))
    );
  },

  get: (key) => JSON.parse(window.localStorage.getItem(key)),

  remove: (item) => { window.localStorage.removeItem(item); },

  clear: () => { window.localStorage.clear(); },
};
