import { isEmpty, localStorage } from 'utils';

export function authHeader() {
  const accessToken = localStorage.get('accessToken');

  if (isEmpty(accessToken)) {
    return { 'content-type': 'application/json' };
  }
  return {
    'content-type': 'application/json',
    authorization: `Bearer ${accessToken}`,
  };
}
