/* eslint-disable no-return-await */
/* eslint-disable import/no-cycle */
import { APIService, API_CONSUMER } from '..';

const BaseURL = `${API_CONSUMER}/api/v1/website`;

export default {
  getWebsiteContent: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getWebsiteContent`, method: 'POST' }),

  updateWebsiteContent: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateWebsiteContent`, method: 'POST' }),

  uploadFiles: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/uploadFiles`, method: 'POST' }),

  sendJobApplication: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/sendJobApplication`, method: 'POST' }),

  getJobApplications: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getJobApplications`, method: 'POST' }),

  sendContactRequest: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/sendContactRequest`, method: 'POST' }),
};
