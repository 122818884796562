import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { RouterLink } from 'routes/components';
import logo from 'assets/images/a1abilities-logo.png';
import logoDark from 'assets/images/a1abilities-logo-dark.png';

// import { LogoLink, LogoText, LogoTextA1 } from './misc';
// ----------------------------------------------------------------------

export default function Logo({ mode="light", style={} }) { 
  return (
    <RouterLink href="/" >
      {/* {mode === "light" ? ( */}
        <img width={140} src={mode === "light" ? logo : logoDark} alt="logo" />  
      {/* ) : (
        <div style={{ padding: '8px', borderRadius: '4px', backgroundColor: '#fff' }}>
          <img width={140} src={logo} alt="logo" />
        </div>
      )}         */}
      
      {/* <LogoLink tw="flex" style={{...style}}>
        {mode === "light" ? (
          <LogoText><LogoTextA1>A1</LogoTextA1>Abilities</LogoText>
        ) : (
          <LogoText tw="text-white"><LogoTextA1>A1</LogoTextA1>Abilities</LogoText>
        )}        
      </LogoLink> */}
    </RouterLink>
  );
};
