import { BodyContainer, HeaderContainer, MainContainer } from 'components/misc';
import { LogoOnlyHeader } from 'layouts/headers';

// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  return (
    <>
      <MainContainer>
        <HeaderContainer>
          <LogoOnlyHeader />
        </HeaderContainer>
        <BodyContainer>
          {children}
        </BodyContainer>
      </MainContainer>
    </>
  );
};
