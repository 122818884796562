
import styled from "styled-components";
import tw from "twin.macro";
import { IconifyIcons } from "components/Iconify";


import EmailIllustrationSrc from "assets/images/email-illustration.svg";

import { ReactComponent as SvgDecoratorBlob1 } from "assets/images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "assets/images/svg-decorator-blob-2.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "assets/images/svg-decorator-blob-3.svg";
import { ReactComponent as SvgDecoratorBlob4 } from "assets/images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob5 } from "assets/images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob6 } from "assets/images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob7 } from "assets/images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob8 } from "assets/images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob9 } from "assets/images/svg-decorator-blob-9.svg";
import { ReactComponent as SvgDecoratorBlob10 } from "assets/images/svg-decorator-blob-10.svg";
import { ReactComponent as SvgDotPatternIcon } from "assets/images/dot-pattern.svg";

export const DecoratorBlob1S1 = tw(SvgDecoratorBlob1)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
export const DecoratorBlob1S2 =styled(SvgDecoratorBlob1)`${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}`;

export const DecoratorBlob2S1 = tw(SvgDecoratorBlob2)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export const DecoratorBlob3S1 = styled(SvgDecoratorBlob3)`${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}`;
export const DecoratorBlob3S2 = styled(SvgDecoratorBlob3)`${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}`;
export const DecoratorBlob3S3 = styled(SvgDecoratorBlob3)`${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}`;

export const DecoratorBlob7S1 = styled(SvgDecoratorBlob7)`${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}`;
export const DecoratorBlob8S1 = styled(SvgDecoratorBlob8)`${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}`;

export const DotPatternBlob1S1 = styled(SvgDotPatternIcon)`${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}`;
export const DotPatternBlob1S2 = tw(SvgDotPatternIcon)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`
export const DotPatternBlob1S3 =tw(SvgDotPatternIcon)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`
export const DotPatternBlob1S4 = styled(SvgDotPatternIcon)(() => [tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`]);

export const DotPatternBlob9S1 = tw(SvgDecoratorBlob9)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-gray-800 opacity-50`;
export const DotPatternBlob9S2 = tw(SvgDecoratorBlob9)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-gray-800 opacity-50`;


export const LeftIcon1 = tw(IconifyIcons.quoteLeftFill)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
export const RightIcon1 = tw(IconifyIcons.quoteRightFill)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

export {
  SvgDecoratorBlob1,
  SvgDecoratorBlob2,
  SvgDecoratorBlob3,
  SvgDecoratorBlob4,
  SvgDecoratorBlob5,
  SvgDecoratorBlob6,
  SvgDecoratorBlob7,
  SvgDecoratorBlob8,
  SvgDecoratorBlob9,
  SvgDecoratorBlob10,
  EmailIllustrationSrc
};