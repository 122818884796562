import PropTypes from 'prop-types';

import { BodyContainer, HeaderContainer, MainContainer } from 'components/misc/Layouts';
import { navConfig } from 'layouts/config-navigation';
import Headers from '../headers';

import Footers from '../footers';

// ----------------------------------------------------------------------

export default function MainLayout({ children, headerStyle="main", footerStyle="style1", style, ...other }) {
  const Footer = Footers[footerStyle]
  const Header = Headers[headerStyle];
  
  return (
    <>
      <MainContainer tw='pt-0'>
        <HeaderContainer>
          <Header links={navConfig} />
        </HeaderContainer>
        <BodyContainer>
          {children}
          <Footer />
        </BodyContainer>
      </MainContainer>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
