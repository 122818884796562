import { DangerContainer, TextHighlight } from 'components/misc'

export default function Danger({ tag= "Error:", message }) {
  return (
    <DangerContainer role="alert">
      <TextHighlight>{tag}</TextHighlight>&nbsp;&nbsp;&nbsp;
      {message}
    </DangerContainer>
  )
}
