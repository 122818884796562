import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

export const ButtonContainer = tw.div`flex justify-center`;

export const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
export const PrimaryButton1 = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;
export const PrimaryButton2 = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
export const PrimaryButton3 = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export const IconButton = tw.button`px-2 py-2 rounded-full bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;


export const LogoutButton = tw.button`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 lg:mx-0 px-8 py-3 rounded bg-red-500 hocus:bg-red-700 text-gray-100 hocus:text-gray-200 focus:shadow-outline`;

export const SubmitButton1 = tw(PrimaryButtonBase)`bg-white text-primary-500 inline-block mt-12 hover:text-primary-900 hover:bg-white`;
export const SubmitButton2 = tw(PrimaryButtonBase)`inline-block mt-8`
export const SubmitButton3 = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {${tw`w-6 h-6 -ml-2`}}
  .text {${tw`ml-3`}}
`;

export const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export const WatchVideoButton1 = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
export const CloseModalButton1 = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
