/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import bytes from 'bytes';

const getFileSize = ({ files, minSize = 0, maxSize = 0, fSExt = 'B' }) => {
  // fSExt = B/KB/MB/GB/TB
  const docs = [];
  let errorMessage = ``;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file) {
      const fSize = bytes(file.size, { unit: fSExt, unitSeparator: ' ' });
      const fRoundedSize = Number(String(fSize).split(' ')[0]);
      if (fRoundedSize > maxSize) {
        docs.push({ filename: file.name, size: fSize, minSize, maxSize, fSExt });
        errorMessage += `file ${file.name} should be less than ${maxSize} ${fSExt}. \n`;
      } else if (fRoundedSize < minSize && minSize !== 0) {
        docs.push({ filename: file.name, size: fSize, minSize, maxSize, fSExt });
        errorMessage += `file ${file.name} should be greater than ${maxSize} ${fSExt}. \n`;
      }
    }
  }
  return { files: docs, errorMessage };
};

export default getFileSize;
