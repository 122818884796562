/* eslint-disable import/no-extraneous-dependencies */
import tw from "twin.macro";
import ReactQuill, { Quill } from 'react-quill';

import EditorToolbar, { formats, redoChange, undoChange } from './editor-toolbar';

const Label = tw.label`w-full ml-1 font-semibold text-base text-gray-800`;
const Error = tw.label`w-full ml-1 text-red-600 text-xs `;

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  simple = false,
  style,
  label,
  required=false,
  placeholder="Write something awesome...",
  ...other
}) {
  const modules = {
    toolbar: {
      container: `#${id}`,
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize',  'Toolbar']
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {label && <Label>{label}{required ? " *" : ""}</Label>}
      <div
        style={{
          ...(error ? {border: `1px solid red` } : {}),
          ...style,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />
        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          {...other}
        />
      </div>

      {error && <Error>{error}</Error>}
    </div>
  );
};

