/* eslint-disable react/no-unstable-nested-components */
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import Logo from "components/logo";
import { IconifyIcons } from "components/Iconify";

import useAnimatedNavToggler from "hooks/useAnimatedNavToggler";
import { DesktopNavLinks, Header, LogoutButton, MobileNavLinks, MobileNavLinksContainer, NavLink, NavLinks, NavToggle, SecondaryLink } from "components/misc";
import useAuth from "hooks/use-auth";
import { RouterLink } from "routes/components";

// -------------------------------------------------------------------

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};

// -------------------------------------------------------------------


export default function MainHeader({ links, className, collapseBreakpointClass = "lg" }) {
  const { isAuthenticated, logout } = useAuth();

  const NavBar = () => (
    <NavLinks key={1}>
      {links?.map(l => (l?.isBtn) ? (
        <RouterLink href={l?.path}>
          <SecondaryLink 
            key={l?.title}
            css={l?.roundBtn && tw`rounded-full`}             
            tw="lg:ml-12!"
          >
            {l?.title}
          </SecondaryLink>
        </RouterLink>
      ) : ( 
        <RouterLink href={l?.path}>
          <NavLink 
            key={l?.title} 
            href={l?.path} 
          >
            {l?.title}
          </NavLink>
        </RouterLink>
      ))}

      {isAuthenticated && (
        <LogoutButton onClick={() => {logout()}}>
          Logout
        </LogoutButton>
      )}
    </NavLinks>
  );

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        <Logo />
        <NavBar />
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        <Logo />
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          <NavBar />
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? IconifyIcons.closeRounded({ width: 22 }) : IconifyIcons.menuFill({ width: 22})}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

