import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import ReactModalAdapter from 'helpers/ReactModalAdapter';
import { IconifyIcons } from "components/Iconify";

const CloseModalButton = tw.button`absolute top-0 right-0 mt-4 mr-4 hocus:text-primary-500 z-10`;

const StyledDilaog = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`bg-[#00000080] fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {  
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
`;

const Title = tw.div`w-full py-4 px-8 absolute flex top-0 border-b-2 font-bold text-lg`;
const ActionStyle = tw.div`w-full py-4 px-8 absolute flex bottom-0 border-t-2  justify-end`;
const ContentStyle = tw.div`w-full h-full mt-32 lg:p-8`;

export default function Dialog({
  children,
  title="Dialog",
  actions=null,
  closeTimeoutMS=300,
  className="mainHeroModal",
  open=false,
  onClose,
  shouldCloseOnOverlayClick=false,
  content=null,
  ...other
}) {
  const props = { closeTimeoutMS, className, isOpen: open, onRequestClose: onClose, shouldCloseOnOverlayClick, ...other };
  return (
    <StyledDilaog 
      {...props}
      maxWidth="xs"
    >
      <CloseModalButton onClick={onClose}>
        {IconifyIcons.closeRounded()}
      </CloseModalButton>
      
      <Title>{title}</Title>
      
      {children}      

    </StyledDilaog>
  )
};

export function DialogContent({ children }) {
  return (
    <ContentStyle>
      { children }
    </ContentStyle>
  )
};

export function DialogActions({ children }) {
  return (
    <ActionStyle>
      {children}
    </ActionStyle>
  )    
};
