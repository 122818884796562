import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

import { RouteProvider } from "contexts/RouteContext";
import { AuthProvider } from "contexts/AuthContext";
import { DataProvider } from "contexts/DataContext";
import Router from "routes/sections";

export default function App() {
  return (
    <>
      <GlobalStyles />
      <RouteProvider>
        <AuthProvider>
          <DataProvider>
            <Router />
            {/* <Router>
              <Routes>
                <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
                <Route path="/components/:type/:name" element={<ComponentRenderer />} />
                <Route path="/thank-you" element={<ThankYouPage />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </Router> */}
          </DataProvider>
        </AuthProvider>
      </RouteProvider>
    </>
  );
};
