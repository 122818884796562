const configureApiRoot = () => {
  const hostname = window && window.location && window.location.hostname;
  if(hostname === 'localhost') {
    return 'http://localhost:5002';
  } return `https://${hostname}`;

    // return 'https://dev.mustcaresupport.com.au';
    // return 'https://mustcaresupport.com.au';
    // return 'http://localhost:5002';
};

const URL = configureApiRoot();
export const API_URL = URL;
export const AUTH_URL = URL;
export const API_CONSUMER = URL;

export const WebImageAPI = `${API_URL}/api/v1/downloadFile?path=webImages`;
export const FileDownloadAPI = `${API_URL}/api/v1/downloadFile`;

console.log('##**API**##', API_CONSUMER);
