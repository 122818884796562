/* eslint-disable array-callback-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */

import * as Yup from 'yup';
import { Fragment, useState, useEffect } from 'react';
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

// hooks
import useAuth from 'hooks/use-auth';
import { IconButton, LogoutButton, PrimaryButton, SectionContainer, SubmitButton2, Table, TableBody, TableContainer, TableData, TableHead, TableHeadData, TableHeadRow, TableRow } from 'components/misc';
import { IconifyIcons } from 'components/Iconify';
import useData from 'hooks/use-data';
import { usePathname } from 'routes/hooks';
import useFormik from 'hooks/use-formik';
import { useBoolean } from 'hooks/use-boolean';
import useIsMounted from 'hooks/use-is-mounted';
import Dialog, { DialogActions, DialogContent } from 'components/dialog';

import { isEmpty } from 'utils';
import { WebsiteV1API } from 'api';
import Alert from 'components/alerts';
import Input from 'components/input';

import Editor from 'components/editor';
import BrowseAndUpload from './BrowseAndUpload';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const schemaString = Yup.string();
const schemaStringRequired = Yup.string().required('Field is required');
const schemaNumber = Yup.number();
const schemaNumberRequired = Yup.number().required('Message is required');

function FormContent({ data, dialog, colView, hideAddButton, hideRemoveButton, subPages }) {
  const isMounted = useIsMounted();
  const { getWebsiteContent } = useData();
  const pathname = usePathname();

  const { id, page, section, value, value_type, schema } = data || "";

  const isArray = value_type === 2;

  const [fields, setFields] = useState({});
  const [validationSchema, setValidationSchema] = useState({});

  const INITIAL_VALUES = !isArray ? { ...value } : {
    arrayOfValues: [...(value || [])]
  };

  useEffect(() => {
    const temp = {};
    const validations = {};

    Object?.keys((schema || {}))?.map((key) => {
      const { defaultValue, required, type } = schema?.[key];
      temp[key] = defaultValue || "";

      if(required || Number(required) === 1) {
        if(type === "number"){
          validations[key] = schemaNumberRequired;
        } else {
          validations[key] = schemaStringRequired;
        }
      } else if(type === "number"){
          validations[key] = schemaNumber;
        } else {
          validations[key] = schemaString;
        };
    });
    setFields(temp);
    setValidationSchema(validations);
  }, [schema]);

  const FinalSubmit = async ({ values, formikHelpers }) => {
    const { setErrors, setSubmitting, resetForm } = formikHelpers;
    const value = isArray ? values?.arrayOfValues : values;
    try {
      const { isSuccess, message } = await WebsiteV1API.updateWebsiteContent({
        id, 
        value_type,
        value,
        subPages,
        isArray
      });
      if(isMounted()){
        if (isSuccess) {
          // toast.success(message);
          dialog?.onFalse?.();
          getWebsiteContent?.({ page: pathname });
        } else {
          setErrors({'afterSubmit': message});
        }
      }
    } catch (error) {
      if(isMounted()) {
        console.error(error);
        setErrors({'afterSubmit': error });
      }
    }
  };

  const arraySchema = Yup.object().shape({
    arrayOfValues: Yup.array().of(Yup.object().shape(validationSchema)),
  });
  const objectSchema =  Yup.object().shape(validationSchema);
  const ValSchema = isArray ? arraySchema : objectSchema;

  const { values, errors, setFieldValue, touched, isSubmitting, handleSubmit, getFieldProps } = useFormik({
    INITIAL_VALUES,
    validationSchema: ValSchema,
    callback: FinalSubmit,
  });

  return (
    <>
      <DialogContent>
        <TableContainer>
            <Table className="">
                <TableHead 
                  style={colView ? { display: "none"} : {}}
                >
                  <TableHeadRow>
                    {Object?.keys((schema || {}))?.map(key => schema?.[key]?.type === 'richtext' ? 
                      (
                        <TableHeadData tw={"min-w-[30rem]"} scope="col" key={key}>
                          {schema?.[key]?.label}
                        </TableHeadData>
                      ) : (
                        <TableHeadData tw={"min-w-[12rem]"} scope="col" key={key}>
                          {schema?.[key]?.label}
                        </TableHeadData>
                      )
                    )}
                    {(isArray || !hideRemoveButton) && (
                      <TableHeadData scope="col" key="remove" align='right' />
                    )}
                  </TableHeadRow>
                </TableHead>
                <TableBody>
                  {isArray ? (
                    <>
                      {(values?.arrayOfValues || [])?.map((row, index) => {
                        const touchedExist = !isEmpty(touched.arrayOfValues) ? !isEmpty(touched.arrayOfValues[index]) : false;
                        const errorsExist = !isEmpty(errors.arrayOfValues) ? !isEmpty(errors.arrayOfValues[index]) : false;
                        const isLoaded = Boolean(touchedExist && errorsExist);
                        
                          return (
                            <TableRow 
                              key={index?.toString()} 
                              tw="bg-transparent hocus:bg-transparent"
                              style={colView ? { display: 'grid' } : {}}
                            >
                              {Object?.keys(fields)?.map((field) => (
                                <TableData tw='p-2' key={field}>
                                  {schema?.[field]?.type === 'richtext' ? (
                                    <Editor
                                      simple
                                      label={colView ? schema?.[field]?.label : ''}
                                      placeholder={schema?.[field]?.placeholder}
                                      required={schema?.[field]?.required}
                                      style={{
                                        border: '1px solid #e9ecee',
                                      }}
                                      {...getFieldProps(`arrayOfValues.${index}.${field}`)}
                                      id={`arrayOfValues-${index}-${field}`}
                                      onChange={(val) => {
                                        setFieldValue(`arrayOfValues.${index}.${field}`, val);
                                      }}
                                      onBlur={(val) => {
                                        // console.log(val);
                                        // setFieldValue(`arrayOfValues.${index}.${field}`, val);
                                      }}
                                      error={
                                        isLoaded &&
                                        !isEmpty(touched.arrayOfValues[index][field]) &&
                                        !isEmpty(errors.arrayOfValues[index][field])
                                          ? errors.arrayOfValues[index][field]
                                          : ''
                                      }
                                    />
                                  ) : (
                                    <Input
                                      rows={4}
                                      multiline={schema?.[field]?.multiline}
                                      label={colView ? schema?.[field]?.label : ''}
                                      placeholder={schema?.[field]?.placeholder}
                                      type={
                                        schema?.[field]?.type === 'file'
                                          ? 'text'
                                          : schema?.[field]?.type || 'text'
                                      }
                                      required={schema?.[field]?.required}
                                      {...getFieldProps(`arrayOfValues.${index}.${field}`)}
                                      error={
                                        isLoaded &&
                                        !isEmpty(touched.arrayOfValues[index][field]) &&
                                        !isEmpty(errors.arrayOfValues[index][field])
                                          ? errors.arrayOfValues[index][field]
                                          : ''
                                      }
                                      endAdornment={(schema?.[field]?.type === 'file') ? (
                                        <BrowseAndUpload
                                          callback={({ files }) => {
                                            setFieldValue(
                                              `arrayOfValues.${index}.${field}`,
                                              files[0]
                                            );
                                          }}
                                        />
                                      ) : null }                                    
                                    />
                                  )}
                                </TableData>
                              ))}
                              {!hideRemoveButton && (
                                <TableData align="right">
                                  <IconButton                                    
                                    onClick={() => {
                                      const dir = [...values.arrayOfValues];
                                      dir.splice(index, 1);
                                      setFieldValue('arrayOfValues', [...dir]);
                                    }}
                                  >
                                    {IconifyIcons.delete({ width: 24, style: { marginRight: 0.5 } })}
                                  </IconButton>
                                </TableData>
                              )}
                            </TableRow>
                          );
                      })}
                      {!hideAddButton && (
                        <TableRow tw="bg-transparent border-b-0 hocus:bg-none">
                          <TableData tw='bg-transparent	'>
                            <PrimaryButton
                              tw="bg-transparent hocus:bg-transparent	text-primary-500 p-0 hocus:text-primary-900"
                              type="button"
                              onClick={() => {
                                setFieldValue('arrayOfValues', [ ...values.arrayOfValues, fields ]);
                              }}
                            >
                              Add more
                            </PrimaryButton>
                          </TableData>
                        </TableRow>
                      )}
                    </>
                  ) : (
                    <TableRow>
                      {Object?.keys(INITIAL_VALUES)?.map(key => (                        
                        <TableData>
                          <Input
                            multiline={schema?.[key]?.multiline}
                            rows={6}
                            name={key}
                            placeholder={schema?.[key]?.placeholder}
                            type={schema?.[key]?.type || "text"}
                            required={schema?.[key]?.required}                                                
                          />                          
                        </TableData>
                      ))}
                    </TableRow>
                  )}
                </TableBody>                
                {errors?.afterSubmit && ( <Alert message={errors?.afterSubmit} /> )}
            </Table>
        </TableContainer>                  
      </DialogContent>

      <DialogActions>
        <LogoutButton onClick={dialog?.onFalse}>Cancel</LogoutButton>
        <SubmitButton2
          tw='mt-0'
          autoFocus
          type="submit"
          loading={isSubmitting}
          onClick={handleSubmit}
          disabled={isEmpty(data)}
        >
          Save
        </SubmitButton2>
      </DialogActions>
    </>
  )
};

function MyDilaog({ maxWidth, data, colView, hideAddButton, hideRemoveButton, subPages }) {
  const { isAuthenticated } = useAuth();
  const dialog = useBoolean();
  
  if(!isAuthenticated) return null;

  return (
    <>
      <IconButton
        tw="mr-4 absolute z-10 px-2 py-2 rounded-full right-0"
        onClick={dialog?.onTrue}
      >
        {IconifyIcons.edit({ width: 24, style: { marginRight: 0.5 } })}        
      </IconButton>

      {dialog?.value && (
        <Dialog 
          open={dialog?.value} 
          onClose={dialog?.onFalse}
          title="Section Update Modal"
          maxWidth={maxWidth}
        >
          <FormContent
            data={data}
            dialog={dialog}
            colView={colView}
            hideAddButton={hideAddButton}
            hideRemoveButton={hideRemoveButton}
            subPages={subPages}
          />
        </Dialog>
      )}
    </>
  )
};

export default function SectionEdit({ 
  children, 
  editable=true,
  data=null, 
  maxWidth="lg", 
  colView=false, 
  hideAddButton=false, 
  hideRemoveButton=false, 
  subPages=null,
  ...rest 
}) {
  const passingProps = { data, maxWidth, colView, hideAddButton, hideRemoveButton, subPages };
  const { isAuthenticated } = useAuth();

  return (!isAuthenticated || !editable) ? children : (
    <SectionContainer
      style={{
        borderRadius: 5,
        border: "1px solid #145665",
        padding: 5,
        position: 'relative',
        marginY: 2,
      }}
      {...rest}
    >
      <MyDilaog {...passingProps} />
      {children}
    </SectionContainer>
  )
};
