/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import * as Yup from 'yup';

import useFormik from 'hooks/use-formik';
import { useBoolean } from 'hooks/use-boolean';
import useIsMounted from 'hooks/use-is-mounted';

import getFileSize from 'utils/getFileSize';
import filesConvertInBase64 from 'utils/filesConvertInBase64';

import { WebsiteV1API } from 'api';

import { IconifyIcons } from 'components/Iconify';

import Dialog, { DialogActions, DialogContent } from 'components/dialog';
import { IconButton, LogoutButton, PrimaryButton, SubmitButton2 } from 'components/misc';
import Input from "components/input";

// ----------------------------------------------------------------------
const Error = tw.div`w-full text-red-600 ml-4`;
// ----------------------------------------------------------------------
const validationSchema = Yup.object().shape({
  files: Yup.string().required('Value is required')
});

const INITIAL_VALUES = {
  filesValue: '',
  files: [],
};

function FormContent({ callback, dialog }) {
  const isMounted = useIsMounted();

  const FinalSubmit = async ({ values, formikHelpers }) => {
    const { setErrors, setSubmitting } = formikHelpers;
    try {
      const files = values.files;
      const CVSize = await getFileSize({ files, maxSize: 2, fSExt: 'MB' });
      if (CVSize.errorMessage) { throw(CVSize.errorMessage); }

      const base64 = await filesConvertInBase64(files)?.then((res) => res?.map(f => ({ name: f.name, data: f.data })));
      const { isSuccess, message, data } = await WebsiteV1API.uploadFiles({ files: base64 });
      if(isMounted()){
        if (isSuccess) {
          callback?.({ files: data });
          dialog.onFalse();
        } else {
          setErrors({'value': message });
        }
      }
    } catch (error) {
      if(isMounted()) {
        console.error(error);
        setSubmitting(false);
        setErrors({'value': error });
      }
    }
  };

  const { values, errors, isSubmitting, setValues, handleSubmit } = useFormik({ INITIAL_VALUES, validationSchema, callback: FinalSubmit });

  return (
    <>
      <DialogContent>
        <div style={{ flexDirection: 'column' }} >          
          <Input
            type='file' 
            value={values?.filesValue} 
            onChange={(e) => {
              setValues({
                ...values,
                filesValue : e?.target?.value,
                files: e?.target?.files
              })
            }}
          />
          {errors?.value && (<Error>{errors?.value}</Error>)} 
        </div>
      </DialogContent>
    
      <DialogActions>
        <LogoutButton disabled={isSubmitting} onClick={dialog?.onFalse}>Cancel</LogoutButton>
        <SubmitButton2
          tw='mt-0'
          autoFocus
          type="submit"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Update
        </SubmitButton2>
      </DialogActions>
    </>
  )
};

export default function BrowseAndUpload({ maxWidth="sm", callback, ...rest }) {
  const dialog = useBoolean();

  return (
    <>
      <IconButton
        tw='bg-transparent text-primary-500 hocus:bg-primary-200'
        onClick={dialog?.onTrue}
      >
        {IconifyIcons.edit({ width: 16 })}
      </IconButton>
      {dialog?.value && (
        <Dialog
          fullWidth
          open={dialog?.value} 
          onClose={dialog?.onFalse}
          title="Upload Files"
          maxWidth={maxWidth}          
        >
          <FormContent dialog={dialog} callback={callback} />
        </Dialog>
      )}
    </>
  )
};
