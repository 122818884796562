import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw.div`w-full relative`;
const InputContainer =tw.div`w-full relative flex`;
const TextField = styled.input`
  ${tw`w-full px-4 py-3 rounded-md font-medium bg-gray-100 border border-gray-400 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-500 focus:bg-white first:mt-0`}  
  ${props => props.hasEndAd ? tw`pr-10` : `` }
  ${props => props.hasStartAd ? tw`pl-10` : `` }
`;
const TextArea = styled.textarea`
  ${tw`w-full px-4 py-3 rounded-md resize-none font-medium bg-gray-100 border border-gray-400 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-500 focus:bg-white first:mt-0`}  
  ${props => props.hasEndAd ? tw`pr-10` : `` }
  ${props => props.hasStartAd ? tw`pl-10` : `` }
`;
const Label = tw.label`w-full ml-1 font-semibold text-base text-gray-800`;
const Error = tw.label`w-full ml-1 text-red-600 text-xs `;
const StartAdornment = tw.div`absolute left-0 top-0 mt-2 ml-1`;
const EndAdornment = tw.div`absolute right-0 top-0 mt-2 mr-1`;



export default function Input({
  id='input',
  name='name',
  value="",
  onChange,
  onBlur,
  multiple=false,
  multiline=false,
  fullWidth=true,
  size="medium",
  rows=2,
  label="",
  placeholder="",
  type="text",
  required=false,
  error="",
  style,  
  startAdornment=null,
  endAdornment=null,
  ...other
}) {
  const Input = multiline ? TextArea : TextField;
  const props = { id, name, value, multiple, onChange, onBlur, multiline, rows, placeholder, type, required, style, ...other };

  return (
    <Container>
      {label && <Label>{label}{required ? " *" : ""}</Label>}
      <InputContainer>
        {startAdornment && <StartAdornment> {startAdornment} </StartAdornment> }

        <Input
          hasEndAd={!!endAdornment}
          hasStartAd={!!startAdornment}
          {...props} 
        />
        
        {endAdornment && <EndAdornment> {endAdornment} </EndAdornment> }
        
      </InputContainer>
      {error && <Error>{error}</Error>}
    </Container>
  )
};
