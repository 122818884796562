// ----------------------------------------------------------------------

export const paths = {
  // Auth
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  verify: '/auth/verify',

  // Pages
  home: '/',
  contact: '/contact',
  about: '/about-us',
  services: '/services',
  industries: '/industries',
  industriesTypes: {
    'health-n-care': '/industries/health-n-care',
    'franchise-management': '/industries/franchise-management',
    'b2b-marketing': '/industries/b2b-marketing',
  },
  caseStudies: '/case-studies',
  
  blog: '#',

  faqs: '/faqs',
  termsOfUse: '/termsofuse',
  privacyPolicy: '/privacypolicy',
  disclaimer: '/disclaimer',

  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  page404: '/error/404',
  page500: '/error/500',
};

// ----------------------------------------------------------------------
