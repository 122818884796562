import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

export const HeadingContainer = tw.div`flex flex-col items-center justify-between`;
export const HeadingContainer2 = tw.div``
export const HeadingRow = tw.div`flex`;



export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
export const Heading = tw(SectionHeading)`w-full`;
export const Heading1 = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight tracking-widest leading-loose after:content after:block after:border-b-4 after:border-primary-500 after:w-16`;
export const Heading2 = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
export const Heading3 = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
export const Heading4 = tw.h6`leading-none text-xl font-semibold`;
export const Heading5 = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
export const Heading6 = tw.h1`text-2xl xl:text-3xl font-extrabold`;
export const Heading7 = tw(SectionHeading)`text-gray-900 mb-10`;
export const Heading8 = styled.h1`
  ${tw`text-2xl text-center sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {${tw`inline-block mt-2`}}
`;


export const SubheadingBase = tw.h5`font-bold text-primary-500`
export const Subheading = tw.h5`font-bold text-primary-500`
export const Subheading1 = tw(SubheadingBase)`text-center md:text-left`;
export const Subheading2 = tw(SubheadingBase)`mb-4 text-center`;
export const Subheading3 = tw.div`font-semibold text-4xl leading-none text-gray-400`;
export const Subheading4 = tw(SubheadingBase)`mb-4`;
export const Subheading5 = tw(SubheadingBase)`text-gray-100 text-center`;
export const Subheading6 = tw(SubheadingBase)`text-center mb-3`;
export const Subheading7 = tw(SubheadingBase)`text-yellow-500 mb-4 tracking-wider`


export const HighlightedText = tw.span`text-primary-500`;


export const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`;
export const Description1 = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
export const Description2 = tw(SectionDescription)`w-full max-w-none text-center`;
export const Description3 = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
export const Description4 = tw.div`text-sm mt-3 leading-loose text-gray-600 font-medium`;
export const Description5 = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;
export const Description6 = tw(SectionDescription)`w-full text-center`;
export const Description7 = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;
export const Description8 = tw(SectionDescription)`mx-auto text-center`

export const Error1 = tw.h5`font-semibold text-red-500 ml-2 text-sm mb-1`

export const VerticalSpacer = tw.div`mt-10 w-full`


export const Link1 = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
export const Link2 = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

export const Text1 = tw(SectionHeading)`text-gray-100 lg:text-left max-w-none text-3xl leading-snug`;


export const Info1 = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
export const Name1 = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
export const Title1 = tw.p`font-medium text-sm`;


export const Info2 = tw.div`content-center p-8 border-2 border-t-0 rounded-lg rounded-t-none sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2`;
export const Title2 = tw.div`mt-2 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
export const Category1 = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
export const SubCategory1 = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;


export const Address1 = tw.span`leading-relaxed`;
export const AddressLine1 = tw.span`block`;
export const Email1 = tw.span`text-sm mt-6 block text-gray-500`;
export const Phone1 = tw.span`text-sm mt-0 block text-gray-500`;
