import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
  <Icon
    ref={ref}
    className="component-iconify"
    icon={icon}
    style={{ width, height: width, ...sx }}
    {...other}
  />
));

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sx: PropTypes.object,
  width: PropTypes.number,
};

export default Iconify;

// ----------------------------------------------------------------------
function getIcon(icon="", ...props) {
  return <Iconify icon={icon} {...props?.[0]} />
};
// ----------------------------------------------------------------------

export const IconifyIcons = {
  doubleArrowRightRounded: (p) => getIcon("material-symbols:keyboard-double-arrow-right-rounded", p),
  outlineControlPointDuplicate: (p) => getIcon("ic:outline-control-point-duplicate", p),
  star: (p) => getIcon("ooui:un-star", p),
  edit: (p) => getIcon("ooui:edit", p),
  add: (p) => getIcon("material-symbols:add", p),
  delete: (p) => getIcon("ic:round-delete", p),
  roundSubdirectoryArrowRight: (p) => getIcon("ic:round-subdirectory-arrow-right", p),
  preview: (p) => getIcon("material-symbols:preview", p),
  roundFormatsize: (p) => getIcon("ic:round-format-size", p),
  shortTextRounded: (p) => getIcon("material-symbols:short-text-rounded", p),
  subjectRounded: (p) => getIcon("material-symbols:subject-rounded", p),
  radioButtonLine: (p) => getIcon("ri:radio-button-line", p),
  checkBoxRounded: (p) => getIcon("material-symbols:check-box-rounded", p),
  roundArrowDropdownCircle: (p) => getIcon("ic:round-arrow-drop-down-circle", p),
  roundCloudUpload: (p) => getIcon("ic:round-cloud-upload", p),
  baselineLinearScale: (p) => getIcon("ic:baseline-linear-scale", p),
  roundDragIndicator: (p) => getIcon("ic:round-drag-indicator", p),
  apps: (p) => getIcon("ion:apps", p),
  roundInsertIinvitation: (p) => getIcon("ic:round-insert-invitation", p),
  roundDraw: (p) => getIcon("ic:round-draw", p),
  generatePdf: (p) => getIcon("carbon:generate-pdf", p),
  baselineDownload: (p) => getIcon("ic:baseline-download", p),
  article: (p) => getIcon("material-symbols:article", p),
  libraryAdd: (p) => getIcon("material-symbols:library-add", p),
  outlineContentCopy: (p) => getIcon("ic:outline-content-copy", p),
  exportBold: (p) => getIcon("ph:export-bold", p),
  deleteRestore: (p) => getIcon("mdi:delete-restore", p),
  baselineAssignmentInd: (p) => getIcon("ic:baseline-assignment-ind", p),
  formstack: (p) => getIcon("simple-icons:formstack", p),
  personAdd20Filled: (p) => getIcon("fluent:person-add-20-filled", p),
  visibility: (p) => getIcon("material-symbols:visibility", p),
  visibilityOff: (p) => getIcon("material-symbols:visibility-off", p),
  twotoneArrowBackIos: (p) => getIcon("ic:twotone-arrow-back-ios", p),
  addCircleOutline: (p) => getIcon("material-symbols:add-circle-outline", p),
  closeRounded: (p) => getIcon("material-symbols:close-rounded", p),
  checkboxBlankOutline: (p) => getIcon("mdi:checkbox-blank-outline", p),
  radioButtonUnchecked: (p) => getIcon("mdi:radio-button-unchecked", p),
  outlineCopyAll: (p) => getIcon("ic:outline-copy-all", p),
  attachFill: (p) => getIcon("eva:attach-fill", p),
  checkSmallRounded: (p) => getIcon("material-symbols:check-small-rounded", p),
  dragHorizontal: (p) => getIcon("akar-icons:drag-horizontal", p),
  moreVerticalF: (p) => getIcon("jam:more-vertical-f", p),
  skipForwardBold: (p) => getIcon("ph:skip-forward-bold", p),
  invoiceSend: (p) => getIcon("mdi:invoice-send", p),
  sent: (p) => getIcon("wpf:sent", p),
  personPasskey16Filled: (p) => getIcon("fluent:person-passkey-16-filled", p),
  syncStatusSolid: (p) => getIcon("fluent-mdl2:sync-status-solid", p),
  payment48Filled: (p) => getIcon("fluent:payment-48-filled", p),
  summarize: (p) => getIcon("material-symbols:summarize", p),
  bookmarkAdd20Filled: (p) => getIcon("fluent:bookmark-add-20-filled", p),
  arrow: (p) => getIcon("maki:arrow", p),
  roundMoveDown: (p) => getIcon("ic:round-move-down", p),
  copyAllRounded: (p) => getIcon("material-symbols:copy-all-rounded", p),
  table: (p) => getIcon("bi:table", p),
  folder: (p) => getIcon("material-symbols:folder", p),
  favorite: (p) => getIcon("ic:outline-favorite", p),
  favoriteOutline: (p) => getIcon("material-symbols:favorite-outline", p),
  form24Filled: (p) => getIcon("fluent:form-24-filled", p),
  manageAccounts: (p) => getIcon("material-symbols:manage-accounts", p),
  createNewFolder: (p) => getIcon("material-symbols:create-new-folder", p),
  rename: (p) => getIcon("mdi:rename", p),
  arrowBackIos: (p) => getIcon("material-symbols:arrow-back-ios", p),
  folderMove: (p) => getIcon("mdi:folder-move", p),
  roundStorage: (p) => getIcon("ic:round-storage", p),
  personFill: (p) => getIcon("bi:person-fill", p),
  settingFilled: (p) => getIcon("ant-design:setting-filled", p),
  peopleFill: (p) => getIcon("bi:people-fill", p),
  menuFill: (p) => getIcon("mingcute:menu-fill", p),
  company: (p) => getIcon("mdi:company", p),
  fullscreenFill: (p) => getIcon("mingcute:fullscreen-fill", p),
  fullscreenExitFill: (p) => getIcon("mingcute:fullscreen-exit-fill", p),
  notificationFill: (p) => getIcon("mingcute:notification-fill", p),
  searchBold: (p) => getIcon("iconamoon:search-bold", p),
  baselineMessage: (p) => getIcon("ic:baseline-message", p),
  folderOpen: (p) => getIcon("mdi:folder-open", p),
  roundChangeCircle: (p) => getIcon("ic:round-change-circle", p),
  outlineRefresh: (p) => getIcon("ic:outline-refresh", p),
  outlineAddToDrive: (p) => getIcon("ic:outline-add-to-drive", p),
  sendFilled: (p) => getIcon("carbon:send-filled", p),
  duplicate: (p) => getIcon("heroicons-solid:duplicate", p),
  warning: (p) => getIcon("ion:warning", p),
  error: (p) => getIcon("icon-park-solid:error", p),
  info: (p) => getIcon("material-symbols:info", p),
  roundCheckCircle: (p) => getIcon("ic:round-check-circle", p),
  uploadFill: (p) => getIcon("ph:upload-fill", p),
  error404: (p) => getIcon("tabler:error-404", p),
  saveErrorLeft: (p) => getIcon("marketeq:save-error-left", p),
  
  globeError20Filled: (p) => getIcon("fluent:globe-error-20-filled", p),
  comingSoon02: (p) => getIcon("hugeicons:coming-soon-02", p),
  issueTypeMaintenance: (p) => getIcon("pajamas:issue-type-maintenance", p),
  roundDashboard: (p) => getIcon("ic:round-dashboard", p),
  dashboard: (p) => getIcon("material-symbols:dashboard", p),
  eventAvailable: (p) => getIcon("material-symbols:event-available", p),
  invoiceFill: (p) => getIcon("iconamoon:invoice-fill", p),
  baselineReceipt: (p) => getIcon("ic:baseline-receipt", p),
  listBar16Filled: (p) => getIcon("fluent:list-bar-16-filled", p),
  taskLlistSolid: (p) => getIcon("streamline:task-list-solid", p),
  baselineAccountCircle: (p) => getIcon("ic:baseline-account-circle", p),
  employeeGroupSolid: (p) => getIcon("clarity:employee-group-solid", p),
  userStar: (p) => getIcon("vaadin:user-star", p),
  userTie: (p) => getIcon("fa-solid:user-tie", p),
  invoiceSolid: (p) => getIcon("teenyicons:invoice-solid", p),
  userManager: (p) => getIcon("grommet-icons:user-manager", p),
  personAccounts20Filled: (p) => getIcon("fluent:person-accounts-20-filled", p),
  contacts: (p) => getIcon("material-symbols:contacts", p),
  connectReference: (p) => getIcon("carbon:connect-reference", p),
  flightTakeoff: (p) => getIcon("material-symbols:flight-takeoff", p),
  leaveCircleFilled: (p) => getIcon("pepicons-pop:leave-circle-filled", p),
  baselineRequestQuote: (p) => getIcon("ic:baseline-request-quote", p),
  servicePlan: (p) => getIcon("eos-icons:service-plan", p),
  reportBox: (p) => getIcon("mdi:report-box", p),
  codeFilled: (p) => getIcon("ant-design:code-filled", p),
  check: (p) => getIcon("material-symbols:check", p),
  contentCopy: (p) => getIcon("material-symbols:content-copy", p),
  emojiOutline: (p) => getIcon("mdi:emoji-outline", p),
  arrowForwardIos: (p) => getIcon("material-symbols:arrow-forward-ios", p),
  addAPhoto: (p) => getIcon("material-symbols:add-a-photo", p),
  lightMode: (p) => getIcon("material-symbols:light-mode", p),
  darkMode: (p) => getIcon("material-symbols:dark-mode", p),
  baselineSettingsSuggest: (p) => getIcon("ic:baseline-settings-suggest", p),
  baselineUndo: (p) => getIcon("ic:baseline-undo", p),
  baselineRedo: (p) => getIcon("ic:baseline-redo", p),
  grabHandleCircleFilled: (p) => getIcon("pepicons-pop:grab-handle-circle-filled", p),
  scheduleSend: (p) => getIcon("material-symbols:schedule-send", p),
  cancelScheduleSend: (p) => getIcon("material-symbols:cancel-schedule-send", p),
  listAltOutline: (p) => getIcon("material-symbols:list-alt-outline", p),
  calendarViewDay: (p) => getIcon("material-symbols:calendar-view-day", p),
  scheduleOutline: (p) => getIcon("material-symbols:schedule-outline", p),
  baselineCake: (p) => getIcon("ic:baseline-cake", p),
  baselineMapsHomeWork: (p) => getIcon("ic:baseline-maps-home-work", p),
  flag: (p) => getIcon("material-symbols:flag", p),
  church: (p) => getIcon("material-symbols:church", p),
  mobile: (p) => getIcon("fa-solid:mobile", p),
  roundPhone: (p) => getIcon("ic:round-phone", p),
  baselineEmail: (p) => getIcon("ic:baseline-email", p),
  location: (p) => getIcon("mdi:location", p),
  family: (p) => getIcon("vaadin:family", p),
  nearMe: (p) => getIcon("material-symbols:near-me", p),
  genderBigender: (p) => getIcon("tabler:gender-bigender", p),
  addTask: (p) => getIcon("material-symbols:add-task", p),
  displaySettingsOutline: (p) => getIcon("material-symbols:display-settings-outline", p),
  task2Fill: (p) => getIcon("mingcute:task-2-fill", p),
  shifts24Filled: (p) => getIcon("fluent:shifts-24-filled", p),
  access: (p) => getIcon("hugeicons:access", p),
  security: (p) => getIcon("material-symbols:security", p),
  settingAltFill: (p) => getIcon("lets-icons:setting-alt-fill", p),
  leaderboard: (p) => getIcon("material-symbols:leaderboard", p),
  statusChange: (p) => getIcon("tabler:status-change", p),
  description: (p) => getIcon("material-symbols:description", p),
  trendingUp: (p) => getIcon("material-symbols:trending-up", p),
  trendingDown: (p) => getIcon("material-symbols:trending-down", p),
  navArrowDown: (p) => getIcon("iconoir:nav-arrow-down", p),
  navArrowUp: (p) => getIcon("iconoir:nav-arrow-up", p),  
  arrowDown2: (p) => getIcon("iconamoon:arrow-down-2", p),
  whereToVote: (p) => getIcon("material-symbols:where-to-vote", p),
  insertChart: (p) => getIcon("material-symbols:insert-chart", p),
  budgetbakersWallet: (p) => getIcon("arcticons:budgetbakers-wallet", p),
  wallet: (p) => getIcon("vaadin:wallet", p),
  today: (p) => getIcon("material-symbols:today", p),
  openInFull: (p) => getIcon("material-symbols:open-in-full", p),
  closeFullscreen: (p) => getIcon("material-symbols:close-fullscreen", p),
  assignment: (p) => getIcon("material-symbols:assignment", p),
  autofpsSelect: (p) => getIcon("material-symbols:autofps-select", p),
  chat: (p) => getIcon("material-symbols:chat", p),
  feed: (p) => getIcon("material-symbols:feed", p),
  settingsBackupRestore: (p) => getIcon("material-symbols:settings-backup-restore", p),
  baselineViewWeek: (p) => getIcon("ic:baseline-view-week", p),
  gridNineFill: (p) => getIcon("ph:grid-nine-fill", p),
  autorenew: (p) => getIcon("material-symbols:autorenew", p),
  calendarCancel20Filled: (p) => getIcon("fluent:calendar-cancel-20-filled", p),
  switchLeft: (p) => getIcon("material-symbols:switch-left", p),
  personRemove: (p) => getIcon("material-symbols:person-remove", p),
  zoomIn: (p) => getIcon("material-symbols:zoom-in", p),
  zoomOut: (p) => getIcon("material-symbols:zoom-out", p),
  arrowLeftAlt: (p) => getIcon("material-symbols:arrow-left-alt", p),
  arrowRightAlt: (p) => getIcon("material-symbols:arrow-right-alt", p),
  baselineExpandMore: (p) => getIcon("ic:baseline-expand-more", p),
  timelapseOutline: (p) => getIcon("material-symbols:timelapse-outline", p),
  filterAlt: (p) => getIcon("material-symbols:filter-alt", p),
  tableArrowDown: (p) => getIcon("mdi:table-arrow-down", p),
  tableArrowRight: (p) => getIcon("mdi:table-arrow-right", p),
  header: (p) => getIcon("cil:header", p),
  tableRowBefore: (p) => getIcon("dashicons:table-row-before", p),
  tableRowAfter: (p) => getIcon("dashicons:table-row-after", p),
  deleteRowOutlined: (p) => getIcon("ant-design:delete-row-outlined", p),
  insertColumnLeft: (p) => getIcon("ri:insert-column-left", p),
  insertColumnRight: (p) => getIcon("ri:insert-column-right", p),
  deleteColumnOutlined: (p) => getIcon("ant-design:delete-column-outlined", p),
  twotoneFormatColorFill: (p) => getIcon("ic:twotone-format-color-fill", p),
  tableDelete: (p) => getIcon("jam:table-delete", p),
  alignVerticalCenterRounded: (p) => getIcon("material-symbols:align-vertical-center-rounded", p),
  alignHorizontalCenterRounded: (p) => getIcon("material-symbols:align-horizontal-center-rounded", p),
  alignTop: (p) => getIcon("majesticons:align-top", p),
  alignBottom: (p) => getIcon("majesticons:align-bottom", p),
  alignHorizontalCenter: (p) => getIcon("majesticons:align-horizontal-center", p),
  alignVerticalCenter: (p) => getIcon("majesticons:align-vertical-center", p),
  alignLeft: (p) => getIcon("majesticons:align-left", p),
  alignRight: (p) => getIcon("majesticons:align-right", p),
  textAlignJustify: (p) => getIcon("majesticons:text-align-justify", p),
  unlocked: (p) => getIcon("fontisto:unlocked", p),
  locked: (p) => getIcon("fontisto:locked", p),
  inputBoxSolid: (p) => getIcon("streamline:input-box-solid", p),
  webDevIcon: (p) => getIcon("logos:web-dev-icon", p),
  jqueryMobile: (p) => getIcon("logos:jquery-mobile", p),
  engineering: (p) => getIcon("flat-color-icons:engineering", p),
  fileTypeDevcontainer: (p) => getIcon("vscode-icons:file-type-devcontainer", p),
  quoteLeftFill: (p) => getIcon("mingcute:quote-left-fill", p),
  quoteRightFill: (p) => getIcon("mingcute:quote-right-fill", p),
  playBold: (p) => getIcon("solar:play-bold", p),
  playCircle: (p) => getIcon("ph:play-circle", p),
  signIn: (p) => getIcon("lets-icons:sign-in", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),
  // icc: (p) => getIcon("icc", p),


};
