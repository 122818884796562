import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Logo from './logo';

// ----------------------------------------------------------------------

export default function SplashScreen({ style, ...other }) {
  return (
    <> 
      <div
        style={{
          width: "100%",
          height: "100vh",
          zIndex: 9999,
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          ...style,
        }}
        {...other}
      >
        <motion.div
          animate={{
            scale: [1, 0.96, 1, 0.96, 1],
            opacity: [1, 0.48, 1, 0.48, 1],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Logo />
        </motion.div>
      </div>
    </>
  );
}

SplashScreen.propTypes = {
  style: PropTypes.object,
};
