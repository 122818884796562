/* eslint-disable */
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import SimpleLayout from 'layouts/simple';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('pages/auth/login'));
const ForgotPasswordPage = lazy(() => import('pages/auth/forgot-password'));
const VerifyPage = lazy(() => import('pages/auth/verify'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <SimpleLayout>
            <LoginPage />
          </SimpleLayout>
        ),
      },
      {
        element: (
          <SimpleLayout>
            <Outlet />
          </SimpleLayout>
        ),
        children: [
          { path: 'forgot-password', element: <ForgotPasswordPage /> },
          { path: 'verify', element: <VerifyPage /> },
        ],
      },
    ],
  },
];
