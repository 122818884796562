import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import StyledMarkdown from './styles';

const PlainContainer = tw.div``;
// ----------------------------------------------------------------------

export default function Markdown({ container=null, content, firstLetter = false, style }) {
  const Container = container || PlainContainer;
  return (
    <Container>
      <StyledMarkdown
        firstLetter={firstLetter}
        dangerouslySetInnerHTML={{ __html: content }}
        style={style}
      />
    </Container>
  );
};