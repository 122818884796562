/* eslint-disable */
import SimpleLayout from 'layouts/simple';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

const Error404 = lazy(() => import('pages/errors/404'));
const Error500 = lazy(() => import('pages/errors/500'));

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    element: (
      <SimpleLayout>
        <Outlet />
      </SimpleLayout>
    ),
    
    children: [
      { path: '/error/404', element: <Error404 /> },
      { path: '/error/500', element: <Error500 /> },
    ],
  },
];
