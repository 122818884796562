
import FiveColumnDark from './FiveColumnDark';
import FiveColumnWithBackground from './FiveColumnWithBackground';
import FiveColumnWithInputForm from './FiveColumnWithInputForm';
import MiniCenteredFooter from './MiniCenteredFooter';
import SimpleFiveColumn from './SimpleFiveColumn';

const Footers = {
  "style1": FiveColumnDark,
  "style2": FiveColumnWithBackground,
  "style3": FiveColumnWithInputForm,
  "style4": MiniCenteredFooter,
  "style5": SimpleFiveColumn,
};

export { 
  FiveColumnDark,
  FiveColumnWithBackground,
  FiveColumnWithInputForm,
  MiniCenteredFooter,
  SimpleFiveColumn,
};
export default Footers;