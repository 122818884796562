/* eslint-disable react/jsx-no-constructed-context-values */

"use client";

import PropTypes from 'prop-types';
import { useReducer, useContext, createContext } from 'react';

const initialState = {
  title: "",
  subtitle: "",

  // layour
  headerOnDark: false,
  disabledSpacing: false,
  hideToolbarOnScroll: true,
};

const handlers = {
  UPDATE_PARAMS: (state, action) => {
    const updatedValues = action.payload || {};
    return {
      ...state,
      ...updatedValues
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const RouteContext = createContext({
  ...initialState,
  updateParams: () => Promise.resolve(),
});

function RouteProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateParams = async (props) => {
    dispatch({ type: 'UPDATE_PARAMS', payload: props });
  };

  return (
    <RouteContext.Provider
      value={{
        ...state,
        updateParams,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

RouteProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useRouteContext = () => useContext(RouteContext);

// ----------------------------------------------------------------------

const RouteConsumer = RouteContext.Consumer;
export { RouteContext, RouteConsumer, RouteProvider, useRouteContext }
