/* eslint-disable no-return-await */
/* eslint-disable import/no-cycle */

import { APIService, API_CONSUMER } from '..';

const BaseURL = `${API_CONSUMER}/api/v1/static`;

export default {
  getStaticRecordList: async ({ ...payload }) =>  await APIService({ payload, url: `${BaseURL}/getStaticRecordList`, method: 'POST' }),

  insertNewStaticRecord: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/insertNewStaticRecord`, method: 'POST' }),
};
