import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

export const PrimaryLink = tw.a`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`;


export const Header = tw.header`flex justify-between items-center max-w-screen-xl mx-auto`;
export const NavLinks = tw.div`inline-block`;
export const NavLink = tw.a`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`;
export const SecondaryLink = tw(NavLink)`lg:mx-0 px-8 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline border-b-0`;
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black text-primary-500 border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-10 mr-3`}
  }
`;
export const LogoTextA1 = tw.span`bg-primary-500 text-white w-10 h-10 flex mr-2 items-center justify-center`;
export const LogoText = tw.span`text-primary-500 flex items-center justify-center border-primary-500 border-2 pr-2`;

export const DesktopNavLinks = tw.nav`hidden lg:flex flex-1 justify-between items-center`;
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);



